import { Route, Routes, Outlet, Navigate } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import DocumentTitle from 'react-document-title'
import AreasList from './components/AreasList'

const areasBreadcrumbs: Array<PageLink> = [
  {
    title: 'Areas',
    path: '/areas',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const AreasPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='list'
          element={
            <>
              <DocumentTitle title={process.env.REACT_APP_NAME + ' | Areas'}></DocumentTitle>
              {/*  <PageTitle breadcrumbs={branchsBreadcrumbs}>List</PageTitle> */}
              <AreasList />
            </>
          }
        />
        <Route index element={<Navigate to='/areas/list' />} />
      </Route>
    </Routes>
  )
}

export default AreasPage

import axios from 'axios'
import { LoginParams, RequestResetPasswordParams, ResetPasswordParams } from './_models'
import Swal from 'sweetalert2'

export function loginPostApi({ ...params }: LoginParams) {
  const data = {
    username: params.username,
    password: params.password,
  }

  return axios
    .post('/login', data, {
      headers: {
        'Content-Type': 'application/json',
        accept: 'application/json',
      },
    })
    .then((res) => res.data)
}

export function meApi(token?: string) {
  if (!token) {
    return Promise.resolve(null)
  }

  return axios
    .get('/auth/me', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => res.data)
    .then((data) => {
      return data
    })
}

export function forgetPasswordPostAPI(email: string) {
  return axios
    .post(
      '/forget-password',
      { email },
      {
        headers: {
          'content-type': 'application/ld+json',
          accept: 'application/ld+json',
        },
      }
    )
    .then((res) => {
      try {
        if (res && res.data && res.data.status !== 'error') {
          return res.data
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: res.data.message || 'An error occurred while processing your request.',
          })
        }
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'An error occurred while processing your request.',
        })
        throw error
      }
    })
    .catch((error) => {
      console.error(error)
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'An error occurred while processing your request.',
      })
      throw error
    })
}

export function requestResetPasswordPostAPI(
  requestResetPasswordParams: RequestResetPasswordParams
) {
  const { code, ...restParams } = requestResetPasswordParams

  return axios
    .post('/forget-password/verify-otp', { ...restParams, code: code })
    .then((res) => res.data)
}

export function resetPasswordPostAPI(resetPasswordParams: ResetPasswordParams) {
  const { reset_token, password } = resetPasswordParams

  return axios
    .post(
      `/reset-password`,
      {
        reset_token: reset_token,
        password: password,
      },
      {
        headers: {
          'content-type': 'application/ld+json',
          accept: 'application/ld+json',
        },
      }
    )
    .then((res) => {
      if (res && res.data && res.data.status !== 'error') {
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Password reset successfully. Redirecting to login...',
        }).then(() => {
          window.location.href = '/login'
        })
        return res.data
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: res.data.message || 'An error occurred while resetting your password.',
        })
      }
    })
    .catch((error) => {
      console.error(error)
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'An error occurred while resetting your password.',
      })
      throw error
    })
}

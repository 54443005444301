/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { KTSVG } from '../../../_metronic/helpers'
import { Dropdown1 } from '../../../_metronic/partials'
import { fetchUnpaidUsers } from './core/_requests'

type Props = {
  className: string
}

const UnpaidUsers: React.FC<Props> = ({ className }) => {
  const token = localStorage.getItem('@auth/token')
  let [unpaidUsers, setUnpaidUsers] = useState<any[]>([])

  const fetchUnpaidUsersRegistrations = async () => {
    await fetchUnpaidUsers(token ?? '').then((response) => {
      setUnpaidUsers(response)
    })
  }

  useEffect(() => {
    fetchUnpaidUsersRegistrations()
  }, [])
  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header align-items-center border-0 mt-4'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='fw-bold mb-2 text-dark'>Unpaid Users</span>
          <span className='text-muted fw-semibold fs-7'>
            {unpaidUsers && unpaidUsers.length > 0
              ? `${unpaidUsers.length} users have not paid.`
              : 'No unpaid users found.'}
          </span>
        </h3>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body pt-5'>
        {/* begin::Timeline */}
        <div className='timeline-label'>
          {unpaidUsers &&
            unpaidUsers.map((user, index) => (
              <div key={index} className='timeline-item'>
                {/* begin::Label */}
                <div className='timeline-label fw-bold text-gray-800 fs-9'>{user.created}</div>
                {/* end::Label */}
                {/* begin::Badge */}
                <div className='timeline-badge'>
                  <i className='fa fa-genderless text-danger fs-1'></i>
                </div>
                {/* end::Badge */}
                {/* begin::Text */}
                <div className='fw-mormal timeline-content text-muted ps-3'>{user.fullname}</div>
                {/* end::Text */}
                {/* begin::Membership */}
                <div className='fw-normal'>
                  <span
                    className={`badge badge-light-${
                      user.membershipPackage === 'Gold Membership Option'
                        ? 'warning'
                        : user.membershipPackage === 'Silver Membership Option'
                        ? 'secondary'
                        : user.membershipPackage === 'Junior Membership Option (Off-peak hours)'
                        ? 'info'
                        : user.membershipPackage === 'Junior Membership Option'
                        ? 'success'
                        : 'danger'
                    }`}
                  >
                    {user.membershipPackage}
                  </span>
                </div>
                {/* end::Membership */}
                {/* begin::Membership Expiration */}
                <div className='fw-normal'>
                  <span
                    className={`badge badge-light-${user.membershipActive ? 'primary' : 'danger'}`}
                  >
                    {user.membershipExpiration}
                  </span>
                </div>
                {/* end::Membership Expiration */}
              </div>
            ))}
        </div>
        {/* end::Timeline */}
      </div>
      {/* end: Card Body */}
    </div>
  )
}

export { UnpaidUsers }

import { Form } from 'react-bootstrap'
import { useState, useEffect } from 'react'
import AsyncSelect from 'react-select/async'
import { SingleValue } from 'react-select'

interface AutocompleteOption {
  readonly value: string
  readonly label: string
}

interface Props {
  defaultValue?: string
  handleChange: (user: string) => void
  getUsersOptionsForTrainers: (input: string) => Promise<any[]>
  isDisabled?: boolean
  required?: boolean
}

const AutocompleteUsersWidget: React.FC<Props> = ({
  defaultValue,
  handleChange,
  getUsersOptionsForTrainers,
  isDisabled = false,
  required = false,
}) => {
  const [value, setValue] = useState<SingleValue<AutocompleteOption>>(null)

  const loadInitialOptions = async () => {
    if (defaultValue) {
      try {
        const userId = defaultValue.split('/').pop()
        const results = await getUsersOptionsForTrainers(userId || '')
        if (results && results.length > 0) {
          const matchingUser = results.find((user) => user.value === defaultValue) || results[0]
          setValue({
            value: matchingUser.value,
            label: matchingUser.label,
          })
        }
      } catch (error) {
        console.error('Error loading initial user:', error)
      }
    }
  }

  useEffect(() => {
    loadInitialOptions()
  }, [defaultValue])

  const loadOptions = async (inputValue: string) => {
    try {
      const results = await getUsersOptionsForTrainers(inputValue)
      return results.map((item) => ({
        value: item.value,
        label: item.label,
      }))
    } catch (error) {
      console.error('Error loading options:', error)
      return []
    }
  }

  return (
    <Form.Group controlId='formUserAutocomplete'>
      <Form.Label>User</Form.Label>
      <div style={{ width: '100%' }} onClick={(e) => e.stopPropagation()}>
        <AsyncSelect
          cacheOptions
          defaultOptions
          value={value}
          loadOptions={loadOptions}
          onChange={(option, meta) => {
            if (meta.action === 'clear') {
              setValue(null)
              handleChange('')
            } else {
              setValue(option)
              if (option) {
                handleChange(option.value)
              }
            }
          }}
          isClearable={!required}
          isDisabled={isDisabled}
          placeholder='Search for a user...'
          className='w-100'
        />
      </div>
    </Form.Group>
  )
}

export default AutocompleteUsersWidget

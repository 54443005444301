import { Route, Routes, Outlet, Navigate } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import DocumentTitle from 'react-document-title'
import ContactFormsList from './ContactFormList'

const contactFormBreadcrumbs: Array<PageLink> = [
  {
    title: 'Contact Forms',
    path: '/contact-forms',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const ContactFormPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='list'
          element={
            <>
              <DocumentTitle
                title={process.env.REACT_APP_NAME + ' | Contact Forms'}
              ></DocumentTitle>
              {/*  <PageTitle breadcrumbs={branchsBreadcrumbs}>List</PageTitle> */}
              <ContactFormsList />
            </>
          }
        />
        <Route index element={<Navigate to='/list' />} />
      </Route>
    </Routes>
  )
}

export default ContactFormPage

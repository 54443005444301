import React, { SyntheticEvent, useState } from 'react'
import PaymentsResultViewer from './PaymentsResultViewer'

function PaymentsList() {
  const [itemIdForUpdate, setItemIdForUpdate] = useState('')
  const [refreshFunction, setRefreshFunction] = useState<any>(() => {})
  let paymentHistoryHeader = [
    { index: 1, type: 'field', key: 'id', title: 'ID', className: 'min-w-45px', sort: true },
    {
      index: 2,
      type: 'object',
      key: 'user',
      subKey: 'name',
      title: 'Name',
      className: 'min-w-125px',
    },
    {
      index: 3,
      type: 'object',
      key: 'user',
      subKey: 'email',
      title: 'Email',
      className: 'min-w-125px',
    },
    {
      index: 4,
      type: 'field',
      key: 'startsAt',
      title: 'Starts At',
      className: 'min-w-75px',
      postProcess: (data: any) => {
        return new Date(data).toLocaleDateString('en-GB', { timeZone: 'UTC' })
      },
      sort: true,
    },
    {
      index: 5,
      type: 'field',
      key: 'expiresAt',
      title: 'Expires At',
      className: 'min-w-75px',
      postProcess: (data: any) => {
        return new Date(data).toLocaleDateString('en-GB', { timeZone: 'UTC' })
      },
      sort: true,
    },
    {
      index: 6,
      type: 'object',
      key: 'membershipPackage',
      subKey: 'name',
      title: 'Membership Package',
      className: 'min-w-125px',
    },
    {
      index: 7,
      type: 'field',
      key: 'paymentStatus',
      title: 'Payment Status',
      className: 'min-w-125px',
      postProcess: (data: any) => {
        if (data === 'Paid') {
          return <span className='badge badge-success'>Paid</span>
        } else if (data === 'Refunded') {
          return <span className='badge badge-warning'>Refunded</span>
        } else {
          return data
        }
      },
    },
    {
      index: 8,
      type: 'field',
      key: 'paidAmount',
      title: 'Amount',
      className: 'min-w-125px',
      postProcess: (data: any) => {
        return '£' + data
      },
      visible: (row: any) => row.paymentStatus === 'Paid',
    },
    {
      index: 9,
      type: 'field',
      key: 'refundAmount',
      title: 'Amount',
      className: 'min-w-125px',
      postProcess: (data: any) => {
        if (!data || data === 0) {
          return '-'
        } else {
          return '£' + data + ' refunded'
        }
      },
      visible: (row: any) => row.paymentStatus === 'Refunded',
    },
    {
      index: 10,
      type: 'field',
      key: 'transactionId',
      title: 'Transaction ID',
      className: 'min-w-125px',
      postProcess: (data: any) => {
        if (!data) {
          return <small>No transaction ID</small>
        } else {
          return (
            <span
              style={{
                fontSize: '10px',
                backgroundColor: '#e9ecef',
                padding: '2px 6px',
                borderRadius: '4px',
                color: '#495057',
              }}
            >
              {data}
            </span>
          )
        }
      },
    },
  ]

  const filters = [
    {
      name: 'transactionId',
      label: 'Transaction ID',
      type: 'text',
      options: null,
      placeholder: 'Enter transaction ID',
    },
    {
      name: 'paymentStatus',
      label: 'Payment Status',
      type: 'select',
      options: {
        Paid: 'Paid',
        Refunded: 'Refunded',
      },
      placeholder: 'Select payment status',
    },
  ]

  return (
    <>
      <PaymentsResultViewer
        title={'Payment History'}
        filter={filters}
        dataUrl={'/admin/payment_history'}
        editCallback={(id: any, refresh: Function) => {
          setRefreshFunction(() => refresh)
          if (id !== undefined && id !== '') {
            setItemIdForUpdate(id)
          }
        }}
        deleteCallback={(id: any, refresh: Function) => () => {}}
        actionItems={[]}
        header={paymentHistoryHeader}
      />
    </>
  )
}

export default PaymentsList

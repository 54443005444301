import { Route, Routes, Outlet, Navigate } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import DocumentTitle from 'react-document-title'
import UsersList from './UsersList'

const usersBreadcrumbs: Array<PageLink> = [
  {
    title: 'Users',
    path: '/users',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const UsersPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='list/*'
          element={
            <>
              <DocumentTitle title={process.env.REACT_APP_NAME + ' | Users'}></DocumentTitle>
              {/* <PageTitle breadcrumbs={usersBreadcrumbs}>Lists</PageTitle> */}
              <UsersList />
            </>
          }
        />
        <Route index element={<Navigate to='/users/list' />} />
      </Route>
    </Routes>
  )
}

export default UsersPage

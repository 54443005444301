/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect } from 'react'
import { Link, Outlet } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../_metronic/helpers'

const AuthLayout = () => {
  useEffect(() => {
    const root = document.getElementById('root')
    if (root) {
      root.style.height = '100%'
    }
    return () => {
      if (root) {
        root.style.height = 'auto'
      }
    }
  }, [])

  return (
    <div className='d-flex flex-column flex-lg-row flex-column-fluid h-100'>
      <div className='d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-2 bg-white'>
        <div className='d-flex flex-center flex-column flex-lg-row-fluid bg-[#F4F4F4]'>
          <div className='w-lg-500px p-10 login-card-bg'>
            <Outlet />
          </div>
        </div>
        <div className='d-flex flex-center flex-wrap px-5 mb-5'>
          <div className='d-flex fw-semibold text-gray fs-base'>
            <a href='#' id='bottom-text-a' className='px-5' target='_blank'>
              Privacy
            </a>
            <a href='#' id='bottom-text-a' className='px-5' target='_blank'>
              Legal
            </a>
            <a href='#' id='bottom-text-a' className='px-5' target='_blank'>
              Contact Us
            </a>
          </div>
        </div>
      </div>

      <div
        className='d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-1'
        style={{ backgroundImage: `url(${toAbsoluteUrl('/media/auth/login-bg.jpg')})` }}
      >
        <div className='d-flex flex-column flex-center py-15 px-5 px-md-15 w-100'>
          <Link to='/' className='mb-12'>
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/logos/logo-white.svg')}
              className='h-250px'
            />
          </Link>
        </div>
      </div>
    </div>
  )
}

export { AuthLayout }

import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Modal, Button, Form } from 'react-bootstrap'
import {
  fetchUsersList,
  fetchBranchList,
  fetchTrainers,
  fetchAreasList,
  fetchUsersForTrainers,
  fetchAreasForTrainers,
  fetchUsersAutocomplete,
} from '../../../utils/SelectListMethods'
import { hoursOptions } from '../../../utils/Constants'
import { useAuth } from '../../auth/core/AuthProvider'
import Swal from 'sweetalert2'
import AutocompleteUsersWidget from './AutocompleteUsersWidget'

interface BookingEditModalProps {
  itemIdForUpdate: string
  show: boolean
  onClose: () => void
  refresh: Function
}

class BookingRequest {
  trainer?: any
  user?: any
  area?: any
  date?: string
  startTime?: string
  endTime?: string
  status?: string
}

const BookingRequestEditModal: React.FC<BookingEditModalProps> = ({
  itemIdForUpdate,
  show,
  onClose,
  refresh,
}) => {
  const [formData, setFormData] = useState<BookingRequest>({
    trainer: null,
    user: null,
    area: null,
    date: '',
    startTime: '',
    endTime: '',
    status: '',
  })
  const { hasValidRole, user, token } = useAuth()
  const [trainerOptions, setTrainersOptions] = useState<any[]>([])
  const [usersOptions, setUsersOptions] = useState<any[]>([])
  const [areaOptions, setAreaOptions] = useState<any[]>([])
  const [date, setDate] = useState(new Date())
  const [startTime, setStartTime] = useState(new Date())
  const [endTime, setEndTime] = useState(new Date())
  const bookingStatusOptions = [
    { value: 'Pending', label: 'Pending' },
    { value: 'Approved', label: 'Approved' },
    { value: 'Rejected', label: 'Rejected' },
  ]
  let bookingUrl = '/admin/bookings'
  if (hasValidRole == 2) {
    bookingUrl = '/trainer/bookings'
  }
  const fetchSingleData = async () => {
    if (itemIdForUpdate !== 'add') {
      axios
        .get(`${bookingUrl}/${itemIdForUpdate}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('@auth/token')}`,
          },
        })
        .then((response) => {
          getAutocompleteUsersOptions(response.data.user['name'])
          setFormData({
            trainer: response.data.trainer ? response.data.trainer['@id'] : null,
            user: response.data.user ? response.data.user['name'] : null,
            area: response.data.area ? response.data.area['@id'] : null,
            date: response.data.date
              ? new Date(response.data.date).toISOString().substring(0, 10)
              : '',
            startTime: response.data.startTime
              ? new Date(response.data.startTime).toISOString().substring(11, 16)
              : '',
            endTime: response.data.endTime
              ? new Date(response.data.endTime).toISOString().substring(11, 16)
              : '',
            status: response.data.status,
          })
        })
        .catch((error) => {
          console.error('Error fetching data:', error)
        })
    } else {
      setFormData({
        trainer: null,
        user: null,
        area: null,
        date: '',
        startTime: '',
        endTime: '',
        status: '',
      })
    }
  }

  const updateData = async () => {
    const currentDate = new Date().toISOString().split('T')[0]

    const editedStartTime = new Date(`${currentDate}T${formData.startTime}:00Z`).toISOString()
    const editedEndTime = new Date(`${currentDate}T${formData.endTime}:00Z`).toISOString()

    const updatedData = {
      ...formData,
      startTime: editedStartTime,
      endTime: editedEndTime,
    }
    if (hasValidRole == 2) delete updatedData.trainer
    axios
      .patch(`${bookingUrl}/${itemIdForUpdate}`, updatedData, {
        headers: {
          Accept: 'application/ld+json',
          'Content-Type': 'application/merge-patch+json',
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if ((response && response?.status === 200) || response?.status === 201) {
          Swal.fire({
            title: 'Success',
            text: 'Booking updated successfully',
            icon: 'success',
            confirmButtonText: 'OK',
            confirmButtonColor: '#004F44',
          })
          onClose()
        } else {
          Swal.fire({
            title: 'Error',
            text: response?.data?.message || 'Error updating booking',
            icon: 'error',
            confirmButtonText: 'OK',
          })
        }
      })
      .catch((error) => {
        Swal.fire({
          title: 'Error',
          text: error || '',
          icon: 'error',
          confirmButtonText: 'OK',
        })
      })
  }

  const createData = async () => {
    const currentDate = new Date().toISOString().split('T')[0]

    const editedStartTime = new Date(`${currentDate}T${formData.startTime}:00Z`).toISOString()
    const editedEndTime = new Date(`${currentDate}T${formData.endTime}:00Z`).toISOString()
    const newData = Object.fromEntries(
      Object.entries({
        ...formData,
        startTime: editedStartTime,
        endTime: editedEndTime,
      }).filter(([_, value]) => value !== '' && value !== null && value !== undefined)
    )
    if (hasValidRole === 2) delete newData.trainer
    axios
      .post(`${bookingUrl}`, newData, {
        headers: {
          Accept: 'application/ld+json',
          'Content-Type': 'application/ld+json',
          Authorization: `Bearer ${localStorage.getItem('@auth/token')}`,
        },
      })
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          refresh(true)
          Swal.fire({
            title: 'Success',
            text: 'Booking created successfully',
            icon: 'success',
            timer: 700,
          })

          onClose()
        } else {
          Swal.fire({
            title: 'Error',
            text: response.data?.message || '',
            icon: 'error',
            confirmButtonText: 'OK',
          })
        }
      })
      .catch((error) => {
        Swal.fire({
          title: 'Error',
          text: error || '',
          icon: 'error',
          confirmButtonText: 'OK',
        })
      })
  }
  /*
  const getUsersOptions = async () => {
    fetchUsersList(token ?? '')
      .then((response) => {
        setUsersOptions(response)
      })
      .catch((error) => {
        console.error('Error fetching user options:', error)
      })
  }
*/
  const getAutocompleteUsersOptions = async (char: string): Promise<any[]> => {
    try {
      const response = await fetchUsersAutocomplete(
        char,
        token ?? '',
        hasValidRole == 2 ? 'trainer' : 'admin'
      )
      setUsersOptions(response)
      return response
    } catch (error) {
      console.error('Error fetching user options:', error)
      return []
    }
  }

  const getAreaOptions = async () => {
    fetchAreasList(token ?? '')
      .then((response) => {
        setAreaOptions(response)
      })
      .catch((error) => {
        console.error('Error fetching area list:', error)
      })
  }

  const getTrainersOptions = async () => {
    fetchTrainers(token ?? '')
      .then((response) => {
        setTrainersOptions(response)
      })
      .catch((error) => {
        console.error('Error fetching branch list:', error)
      })
  }

  const getAreasOptionsForTrainers = async () => {
    fetchAreasForTrainers(token ?? '')
      .then((response) => {
        setAreaOptions(response)
      })
      .catch((error) => {
        console.error('Error fetching branch list:', error)
      })
  }

  useEffect(() => {
    if (itemIdForUpdate !== 'add') {
      fetchSingleData()
      if (hasValidRole === 2) {
        getAreasOptionsForTrainers()
      }
      if (hasValidRole === 1) {
        getTrainersOptions()
        getAreaOptions()
      }
    }
  }, [itemIdForUpdate])

  useEffect(() => {
    if (hasValidRole === 2) {
      getAreasOptionsForTrainers()
    }
    if (hasValidRole === 1) {
      getTrainersOptions()
      getAreaOptions()
    }
  }, [])

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }))
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    if (itemIdForUpdate === 'add') {
      createData()
    } else {
      updateData()
    }
  }

  return (
    <Modal show={show} onHide={onClose} backdrop='static'>
      <Modal.Header closeButton>
        <Modal.Title>{itemIdForUpdate === 'add' ? 'Add Booking' : 'Edit Booking'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          {hasValidRole === 1 ? (
            <Form.Group controlId='formTrainer'>
              <Form.Label>Trainer</Form.Label>
              <Form.Select name='trainer' value={formData.trainer} onChange={handleChange}>
                <option value=''>Select Trainer</option>
                {trainerOptions.map((trainer) => (
                  <option key={trainer.value} value={trainer.value}>
                    {trainer.label}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          ) : null}

          <Form.Group controlId='formUserAutocomplete'>
            <AutocompleteUsersWidget
              defaultValue={formData.user}
              handleChange={(user: string) => {
                setFormData((prevData) => ({
                  ...prevData,
                  user: user,
                }))
              }}
              getUsersOptionsForTrainers={getAutocompleteUsersOptions}
              required
            />
          </Form.Group>

          <Form.Group controlId='formArea'>
            <Form.Label>Area</Form.Label>
            <Form.Select name='area' value={formData.area} onChange={handleChange}>
              <option value=''>Select Area</option>
              {areaOptions.map((area) => (
                <option key={area.value} value={area.value}>
                  {area.label}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group controlId='formDate'>
            <Form.Label>Date</Form.Label>
            <Form.Control type='date' name='date' value={formData.date} onChange={handleChange} />
          </Form.Group>
          <Form.Group controlId='formStartTime'>
            <Form.Label>Start Time</Form.Label>
            <Form.Select name='startTime' value={formData.startTime} onChange={handleChange}>
              <option value=''>Select Time</option>
              {hoursOptions.map((hour) => (
                <option key={hour.value} value={hour.value}>
                  {hour.label}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group controlId='formEndTime'>
            <Form.Label>End Time</Form.Label>
            <Form.Select name='endTime' value={formData.endTime} onChange={handleChange}>
              <option value=''>Select Time</option>
              {hoursOptions.map((hour) => (
                <option key={hour.value} value={hour.value}>
                  {hour.label}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group controlId='formStatus'>
            <Form.Label>Status</Form.Label>
            <Form.Select name='status' value={formData.status} onChange={handleChange}>
              <option value=''>Select Status</option>
              {bookingStatusOptions.map((status) => (
                <option key={status.value} value={status.value}>
                  {status.label}
                </option>
              ))}
            </Form.Select>
          </Form.Group>

          <div className='text-end mt-3'>
            <Button variant='secondary' onClick={onClose} className='me-2'>
              Cancel
            </Button>
            <Button type='submit' variant='primary'>
              Save changes
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  )
}
export default BookingRequestEditModal

import React, { useEffect, useState } from 'react'
import { MixedWidget11 } from '../../../_metronic/partials/widgets'
import { UnpaidUsers } from './UnpaidUsers'
import { DashboardCard } from './components/DashboardCard'
import { fetchNewUsers, fetchUnpaidUsers } from './core/_requests'

function AdminDashboard() {
  const [showUnpaidUsers, setShowUnpaidUsers] = useState<boolean>(false)
  const token = localStorage.getItem('@auth/token')
  let [newUsers, setNewUsers] = useState<any[]>([])
  let [unpaidUsers, setUnpaidUsers] = useState<any[]>([])

  const fetchNewUsersRegistrations = async () => {
    const now = new Date()
    now.setDate(now.getDate() - 7)
    const formattedDate = `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(
      2,
      '0'
    )}-${String(now.getDate()).padStart(2, '0')}`
    await fetchNewUsers(token ?? '', formattedDate).then((response) => {
      setNewUsers(response)
    })
  }

  const fetchUnpaidUsersRegistrations = async () => {
    await fetchUnpaidUsers(token ?? '').then((response) => {
      setUnpaidUsers(response)
    })
  }

  useEffect(() => {
    fetchNewUsersRegistrations()
    fetchUnpaidUsersRegistrations()
  }, [])
  let newUsersCount = newUsers ? newUsers.length : 0
  let unpaidUsersCount = unpaidUsers ? unpaidUsers.length : 0

  return (
    <>
      {/* begin::Row */}
      <div className='row g-5 g-xl-8'>
        <div className='col-xl-2'>
          <DashboardCard
            className='card-xl-stretch mb-xl-8'
            svgIcon='/media/icons/duotune/general/gen025.svg'
            color='light'
            iconColor='primary'
            title='1'
            description='Branches'
            href='/branches/list'
          />
        </div>
        <div className='col-xl-2'>
          <DashboardCard
            className='card-xl-stretch mb-xl-8'
            svgIcon='/media/icons/duotune/general/gen017.svg'
            color='light'
            iconColor='primary'
            title='5'
            description='Areas'
            href='/areas/list'
          />
        </div>

        <div className='col-xl-2'>
          <DashboardCard
            className='card-xl-stretch mb-xl-8'
            svgIcon='/media/icons/duotune/general/gen059.svg'
            color='light'
            iconColor='primary'
            title='4'
            description='Bays'
            href='/areas/list'
          />
        </div>
        <div className='col-xl-2'>
          <DashboardCard
            className='card-xl-stretch mb-xl-8'
            svgIcon='/media/icons/duotune/general/gen056.svg'
            color='light'
            iconColor='primary'
            title='1'
            description='Puttview'
            href='/areas/list'
          />
        </div>
        <div className='col-xl-2'>
          <DashboardCard
            className='card-xl-stretch mb-xl-8'
            svgIcon='/media/icons/duotune/general/gen049.svg'
            color='light'
            iconColor='primary'
            title='67'
            description='Users'
            href='/users/list'
          />
        </div>
        <div className='col-xl-2'>
          <DashboardCard
            className='card-xl-stretch mb-xl-8'
            svgIcon='/media/icons/duotune/general/gen049.svg'
            color='light'
            iconColor='primary'
            title='12'
            description='Coaches'
            href='/users/list'
          />
        </div>
      </div>
      {/* end::Row */}
      {/* begin::Row */}
      <div className='row g-5 g-xl-8'>
        <div className='col-xl-2'>
          <DashboardCard
            className='card-xl-stretch mb-xl-8'
            svgIcon='/media/icons/duotune/general/gen029.svg'
            color='light'
            iconColor='primary'
            title={newUsersCount.toString()}
            description='New Users'
            href='/users/list'
          />
        </div>
        <div className='col-xl-2'>
          <DashboardCard
            className='card-xl-stretch mb-xl-8'
            svgIcon='/media/icons/duotune/general/gen044.svg'
            color='light'
            iconColor='primary'
            title={unpaidUsersCount.toString()}
            description='Unpaid Users'
            href='#'
            onClick={() => setShowUnpaidUsers(showUnpaidUsers ? false : true)}
          />
        </div>
      </div>

      {/* begin::Row */}
      {showUnpaidUsers && (
        <div className='row g-5 g-xl-12'>
          <UnpaidUsers className='col-xl-12' />
        </div>
      )}

      {/* end::Row */}
      {/* begin::Row */}
      <div className='row g-5 g-xl-8 mt-5'>
        {/* begin::Col */}
        <div className='col-xl-4'>
          <MixedWidget11
            className='card-xl-stretch mb-xl-8'
            chartColor='primary'
            chartHeight='200px'
            title='Booking'
            count='227'
            date=''
          />
        </div>
        {/* end::Col */}

        {/* begin::Col */}
        <div className='col-xl-4'>
          <MixedWidget11
            className='card-xl-stretch mb-xl-8'
            chartColor='primary'
            chartHeight='200px'
            title='Users'
            count='150'
            date=''
          />
        </div>
        {/* end::Col */}

        {/* begin::Col */}
        <div className='col-xl-4'>
          <MixedWidget11
            className='card-xl-stretch mb-xl-8'
            chartColor='primary'
            chartHeight='200px'
            title='Hours'
            count='200'
            date=''
          />
        </div>
        {/* end::Col */}
      </div>
    </>
  )
}

export default AdminDashboard

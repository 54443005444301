import { Route, Routes, Outlet, Navigate } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import DocumentTitle from 'react-document-title'
import BranchesList from './BranchesList'

const branchsBreadcrumbs: Array<PageLink> = [
  {
    title: 'Branches',
    path: '/branches',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const BranchesPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='list'
          element={
            <>
              <DocumentTitle title={process.env.REACT_APP_NAME + ' | Branches'}></DocumentTitle>
              {/*  <PageTitle breadcrumbs={branchsBreadcrumbs}>List</PageTitle> */}
              <BranchesList />
            </>
          }
        />
        <Route index element={<Navigate to='/branches/list' />} />
      </Route>
    </Routes>
  )
}

export default BranchesPage

import axios from 'axios'
import moment from 'moment'

export const fetchUnpaidUsers = async (token: string) => {
  try {
    const response = await axios.get(`/admin/unpaid-users?page=1&pageSize=10`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    let options = []
    if (response.data['hydra:member']) {
      options = response.data['hydra:member'].map((user: any) => ({
        fullname: user['fullname'] ?? user['name'] + ' ' + user['surname'] ?? '',
        membershipPackage: user['membershipPackage'] ? user['membershipPackage']['name'] : '',
        membershipExpiration: user['membershipExpiresAt']
          ? moment.utc(user['membershipExpiresAt']).format('DD/MM/YYYY')
          : '',
        membershipActive: user['membershipActive'] ?? false,
        status: user['status'] ?? '',
      }))
    }
    return options
  } catch (error) {
    console.error('Error fetching user list:', error)
    throw error
  }
}

export const fetchNewUsers = async (token: string, createdAfterAt: string) => {
  try {
    const response = await axios.get(
      `/admin/users?page=1&pageSize=10&createdAfterAt=${createdAfterAt}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    let options = []
    if (response.data['hydra:member']) {
      options = response.data['hydra:member'].map((user: any) => ({
        created: user['created'] ? moment.utc(user['created']).format('DD/MM/YYYY HH:mm') : '',
        fullname: user['fullname'] ?? user['name'] + ' ' + user['surname'] ?? '',
        membershipPackage: user['membershipPackage'] ? user['membershipPackage']['name'] : '',
        membershipExpiration: user['membershipExpiresAt']
          ? moment.utc(user['membershipExpiresAt']).format('DD/MM/YYYY')
          : '',
        membershipActive: user['membershipActive'] ?? false,
        status: user['status'] ?? '',
      }))
    }
    return options
  } catch (error) {
    console.error('Error fetching user list:', error)
    throw error
  }
}

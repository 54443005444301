import React from 'react'
import DocumentTitle from 'react-document-title'
import { Navigate, Outlet, Route, Routes } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import DummyDashboard from './DummyDashboard'
import AdminDashboard from './AdminDashboard'
import { useAuth } from '../auth/core/AuthProvider'

const DashboardPage = () => {
  const dashboardBreadcrumb: Array<PageLink> = [
    {
      title: 'Dashboard',
      path: '/dashboard',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

  const { hasValidRole } = useAuth()
  return (
    <Routes>
      <Route element={<Outlet />}>
        {hasValidRole === 1 && (
          <Route
            path='admin'
            element={
              <>
                <DocumentTitle title={process.env.REACT_APP_NAME + ' | Dashboard'}></DocumentTitle>
                <AdminDashboard />
              </>
            }
          />
        )}
        {hasValidRole === 2 && (
          <Route
            path='trainer'
            element={
              <>
                <DocumentTitle title={process.env.REACT_APP_NAME + ' | Dashboard'}></DocumentTitle>
                <DummyDashboard />
              </>
            }
          />
        )}
        <Route
          index
          element={
            <Navigate
              to={
                hasValidRole === 1
                  ? '/dashboard/admin'
                  : hasValidRole === 2
                  ? '/dashboard/trainer'
                  : '/'
              }
            />
          }
        />
      </Route>
    </Routes>
  )
}

export default DashboardPage

import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Modal, Button, Form, Accordion } from 'react-bootstrap'

interface MembershipPackageEditModalProps {
  itemIdForUpdate: string
  show: boolean
  onClose: () => void
  refresh: Function
}

class MembershipPackage {
  name?: string
  price?: number | null
  availableBookingHours?: Array<{
    days: number[]
    startTime: string
    endTime: string
  }>
  maxMemberCount?: number | null
}

const MembershipPackageEditModal: React.FC<MembershipPackageEditModalProps> = ({
  itemIdForUpdate,
  show,
  onClose,
  refresh,
}) => {
  const [formData, setFormData] = useState<MembershipPackage>({
    name: '',
    price: null,
    availableBookingHours: [],
    maxMemberCount: null,
  })
  const fetchSingleData = async () => {
    if (itemIdForUpdate !== 'add') {
      axios
        .get(`/admin/membership_packages/${itemIdForUpdate}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('@auth/token')}`,
          },
        })
        .then((response) => {
          setFormData({
            name: response.data.name,
            price: Number(response.data.price),
            availableBookingHours: response.data.availableBookingHours,
            maxMemberCount: Number(response.data.maxMemberCount),
          })
        })
        .catch((error) => {
          console.error('Error fetching data:', error)
        })
    } else {
      setFormData({
        name: '',
        price: null,
        maxMemberCount: null,
      })
    }
  }

  const updateData = async () => {
    axios
      .patch(`/admin/membership_packages/${itemIdForUpdate}`, formData, {
        headers: {
          Accept: 'application/ld+json',
          'Content-Type': 'application/merge-patch+json',
          Authorization: `Bearer ${localStorage.getItem('@auth/token')}`,
        },
      })
      .then((response) => {
        onClose()
        refresh(true)
      })
      .catch((error) => {
        console.error('Error updating data:', error)
      })
  }

  const createData = async () => {
    axios
      .post(`/admin/membership_packages`, formData, {
        headers: {
          Accept: 'application/ld+json',
          'Content-Type': 'application/ld+json',
          Authorization: `Bearer ${localStorage.getItem('@auth/token')}`,
        },
      })
      .then((response) => {
        onClose()
        refresh(true)
        setFormData({
          name: '',
          price: null,
          availableBookingHours: [],
          maxMemberCount: null,
        })
      })
      .catch((error) => {
        console.error('Error creating data:', error)
      })
  }

  useEffect(() => {
    fetchSingleData()
  }, [itemIdForUpdate])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    if (name === 'price' || name === 'maxMemberCount') {
      setFormData((prevData) => ({ ...prevData, [name]: Number(value) }))
    } else {
      setFormData((prevData) => ({ ...prevData, [name]: value }))
    }
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    if (itemIdForUpdate === 'add') {
      createData()
    } else {
      updateData()
    }
  }

  const [activeKey, setActiveKey] = useState<string[]>([])

  return (
    <Modal show={show} onHide={onClose} backdrop='static'>
      <Modal.Header closeButton>
        <Modal.Title>
          {itemIdForUpdate === 'add' ? 'Add Membership Package' : 'Edit Membership Package'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId='formUserTitle'>
            <Form.Label>Name</Form.Label>
            <Form.Control
              type='text'
              name='name'
              value={formData.name}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group controlId='formUserPrice'>
            <Form.Label>Price</Form.Label>
            <Form.Control
              type='text'
              name='price'
              value={
                formData.price !== null && formData.price !== undefined
                  ? formData.price.toString()
                  : ''
              }
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group controlId='formMaxMemberCount' className='mt-3'>
            <Form.Label>Max Member Count</Form.Label>
            <Form.Control
              type='number'
              name='maxMemberCount'
              value={
                formData.maxMemberCount !== null && formData.maxMemberCount !== undefined
                  ? formData.maxMemberCount.toString()
                  : ''
              }
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group controlId='formAvailableBookingHours'>
            <div className='d-flex justify-content-between align-items-center mt-3 mb-3'>
              <Form.Label>Available Booking Hours</Form.Label>
              <Button
                size='sm'
                variant='info'
                onClick={() => {
                  setFormData((prevData) => ({
                    ...prevData,
                    availableBookingHours: [
                      ...(prevData.availableBookingHours || []),
                      {
                        days: [],
                        startTime: '1970-01-01T00:00:00+00:00',
                        endTime: '1970-01-01T01:00:00+00:00',
                      },
                    ],
                  }))
                  setActiveKey((prevKeys) => [
                    ...prevKeys,
                    formData.availableBookingHours?.length.toString() || '0',
                  ])
                }}
              >
                <i className='bi bi-plus'></i> Add Booking Slot
              </Button>
            </div>
            {formData.availableBookingHours?.map((bookingHour, index) => (
              <div key={index} className='mb-3'>
                <Accordion
                  activeKey={activeKey}
                  onSelect={(key) => {
                    if (key !== null && key !== undefined) {
                      const newActiveKey = activeKey.includes(key.toString())
                        ? activeKey.filter((k) => k !== key.toString())
                        : [...activeKey, key.toString()]
                      setActiveKey(newActiveKey)
                    }
                  }}
                >
                  <Accordion.Item eventKey={index.toString()}>
                    <Accordion.Header>Booking Slot {index + 1}</Accordion.Header>
                    <Accordion.Body>
                      <Form.Label>Days</Form.Label>
                      <div className='d-flex flex-wrap mb-2'>
                        {[...Array(7)].map((_, dayIndex) => (
                          <Form.Check
                            inline
                            key={dayIndex}
                            type='checkbox'
                            label={['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'][dayIndex]}
                            checked={bookingHour.days.includes(dayIndex + 1)}
                            onChange={(e) => {
                              const days = e.target.checked
                                ? [...bookingHour.days, dayIndex + 1]
                                : bookingHour.days.filter((day) => day !== dayIndex + 1)
                              setFormData((prevData) => {
                                const updatedHours = [...(prevData.availableBookingHours || [])]
                                updatedHours[index] = { ...updatedHours[index], days }
                                return { ...prevData, availableBookingHours: updatedHours }
                              })
                            }}
                            className='me-2'
                          />
                        ))}
                      </div>
                      <Form.Label>Start Time</Form.Label>
                      <Form.Control
                        as='select'
                        value={new Date(bookingHour.startTime).toISOString().substring(11, 16)}
                        onChange={(e) => {
                          const startTime = `1970-01-01T${e.target.value}:00+00:00`
                          setFormData((prevData) => {
                            const updatedHours = [...(prevData.availableBookingHours || [])]
                            updatedHours[index] = { ...updatedHours[index], startTime }
                            return { ...prevData, availableBookingHours: updatedHours }
                          })
                        }}
                        className='mb-2'
                      >
                        {[...Array(24)].map((_, hour) => (
                          <option key={hour} value={`${hour.toString().padStart(2, '0')}:00`}>
                            {`${hour.toString().padStart(2, '0')}:00`}
                          </option>
                        ))}
                      </Form.Control>
                      <Form.Label>End Time</Form.Label>
                      <Form.Control
                        as='select'
                        value={new Date(bookingHour.endTime).toISOString().substring(11, 16)}
                        onChange={(e) => {
                          const endTime = `1970-01-01T${e.target.value}:00+00:00`
                          setFormData((prevData) => {
                            const updatedHours = [...(prevData.availableBookingHours || [])]
                            updatedHours[index] = { ...updatedHours[index], endTime }
                            return { ...prevData, availableBookingHours: updatedHours }
                          })
                        }}
                        className='mb-2'
                      >
                        {[...Array(24)].map((_, hour) => (
                          <option key={hour} value={`${hour.toString().padStart(2, '0')}:00`}>
                            {`${hour.toString().padStart(2, '0')}:00`}
                          </option>
                        ))}
                      </Form.Control>
                      <Button
                        variant='danger'
                        size='sm'
                        onClick={() => {
                          setFormData((prevData) => {
                            const updatedHours = (prevData.availableBookingHours || []).filter(
                              (_, i) => i !== index
                            )
                            return { ...prevData, availableBookingHours: updatedHours }
                          })
                          setActiveKey((prevKeys) =>
                            prevKeys.filter((key) => key !== index.toString())
                          )
                        }}
                      >
                        Remove Booking Slot
                      </Button>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            ))}
          </Form.Group>
          {/* Add more form fields */}
          <div className='text-end mt-3'>
            <Button variant='secondary' onClick={onClose} className='me-2'>
              Cancel
            </Button>
            <Button type='submit' variant='primary'>
              Save changes
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  )
}
export default MembershipPackageEditModal

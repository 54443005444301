import React, { SyntheticEvent, useState } from 'react'
import Swal from 'sweetalert2'
import axios from 'axios'
import ResultViewer from '../../../utils/ResultViewer'
import TrainerAvailabilityEditModal from './TrainerAvailabilityEditModal'
import { useAuth } from '../../auth/core/AuthProvider'
import moment from 'moment'

function TrainerAvailablityCalendarList() {
  const [itemIdForUpdate, setItemIdForUpdate] = useState('')
  const [refreshFunction, setRefreshFunction] = useState<any>(() => {})
  const { user, loggedIn, hasValidRole, token } = useAuth()
  const rolesOptions = {
    ROLE_COACH: 'Coach',
    ROLE_PHYSIO: 'Physio',
    ROLE_TRAINER: 'Trainer',
    ROLE_PERSONAL_TRAINER: 'Personal Trainer',
  }
  const filters = [
    {
      name: 'user',
      label: 'User',
      type: 'text',
    },
    {
      name: 'role',
      label: 'Role',
      type: 'select',
      options: rolesOptions,
    },
  ]
  let bookingHeader = [
    { index: 1, type: 'field', key: 'id', title: 'ID', className: 'min-w-125px', sort: true },
    {
      index: 2,
      type: 'object',
      key: 'user',
      subKey: 'name',
      title: 'User',
      className: 'min-w-125px',
    },
    {
      index: 2,
      type: 'object',
      key: 'user',
      subKey: 'roles',
      title: 'Roles',
      className: 'min-w-125px',
      postProcess: (roles: []) => {
        const rolesMap: { [key: string]: { label: string } } = {
          ROLE_PERSONAL_TRAINER: { label: 'Personal Trainer' },
          ROLE_PHYSIO: { label: 'Physio' },
          ROLE_COACH: { label: 'Coach' },
        }

        const rolesArray = Object.values(roles)
        return rolesArray
          .filter((role: string) => rolesMap[role])
          .map((role: string) => (
            <span
              key={role}
              className='badge badge-light'
              style={{
                fontSize: '1rem',
                marginRight: '0.25rem',
                marginTop: '5px',
              }}
            >
              {rolesMap[role].label}
            </span>
          ))
      },
    },
    {
      index: 3,
      type: 'field',
      key: 'startsAt',
      title: 'Start Date',
      postProcess: (datetime: string) => {
        return datetime && datetime !== 'N/A' ? moment.utc(datetime).format('DD/MM/YYYY') : 'N/A'
      },
      className: 'min-w-125px',
      sort: true,
    },
    {
      index: 4,
      type: 'field',
      key: 'endsAt',
      title: 'End Date',
      postProcess: (datetime: string) => {
        return datetime && datetime !== 'N/A' ? moment.utc(datetime).format('DD/MM/YYYY') : 'N/A'
      },
      className: 'min-w-125px',
      sort: true,
    },
    {
      index: 5,
      type: 'field',
      key: 'days',
      title: 'Days',
      className: 'min-w-125px',
      postProcess: (days: number[]) => {
        const dayPairs = [
          { index: '1', day: 'Monday' },
          { index: '2', day: 'Tuesday' },
          { index: '3', day: 'Wednesday' },
          { index: '4', day: 'Thursday' },
          { index: '5', day: 'Friday' },
          { index: '6', day: 'Saturday' },
          { index: '7', day: 'Sunday' },
        ]
        return days
          .sort((a: number, b: number) => a - b)
          .map((dayIndex: number) => {
            const dayPair = dayPairs.find((pair) => pair.index === String(dayIndex))

            return (
              <span
                key={dayIndex}
                className='badge badge-light'
                style={{ fontSize: '1rem', marginRight: '0.25rem', marginTop: '5px' }}
              >
                {dayPair ? dayPair.day : 'Unknown'}
              </span>
            )
          })
      },
    },
    {
      index: 6,
      type: 'field',
      key: 'startTime',
      title: 'Start Time',
      postProcess: (datetime: string) => {
        if (!datetime) {
          return 'N/A'
        } else {
          const date = new Date(datetime)
          const hour = date.getUTCHours()
          const minutes = date.getUTCMinutes()
          return `${hour.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`
        }
      },
      className: 'min-w-125px',
      sort: true,
    },
    {
      index: 7,
      type: 'field',
      key: 'endTime',
      title: 'End Time',
      postProcess: (datetime: string) => {
        if (!datetime) {
          return 'N/A'
        } else {
          const date = new Date(datetime)
          const hour = date.getUTCHours()
          const minutes = date.getUTCMinutes()
          return `${hour.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`
        }
      },
      className: 'min-w-125px',
      sort: true,
    },
    { index: 8, type: 'field', key: 'actions', title: 'Actions', className: 'min-w-125px' },
  ]
  let apiUrl = '/trainer/availability_calendars'
  localStorage.setItem('@auth/token', token ?? '')
  if (hasValidRole === 2) {
    bookingHeader = bookingHeader.filter((header) => ![2].includes(header.index))
  }
  if (hasValidRole === 1) {
    apiUrl = '/admin/availability_calendars'
  }

  return (
    <>
      <ResultViewer
        title={'Availability List View'}
        dataUrl={apiUrl}
        editCallback={(id: any, refresh: Function) => {
          setRefreshFunction(() => refresh)
          if (id !== undefined && id !== '') {
            setItemIdForUpdate(id)
          }
        }}
        deleteCallback={(id: any, refresh: Function) => {
          Swal.fire({
            title: 'Warning',
            html: 'Are you want to delete this area?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Delete',
            cancelButtonText: 'Cancel',
            customClass: {
              confirmButton: 'btn btn-danger',
              cancelButton: 'btn btn-primary',
            },
          }).then((result) => {
            if (result.isConfirmed) {
              axios.delete(apiUrl + '/' + id).then(() => {
                refresh()
              })
            }
          })
        }}
        actionItems={[
          {
            text: 'Add New Availability',
            class: 'btn btn-sm btn-primary',
            icon: 'fa fa-golf-ball',
            onClick: function (event: SyntheticEvent, refresh: Function) {
              if (itemIdForUpdate !== undefined) {
                setItemIdForUpdate('add')
              }
              setRefreshFunction(() => refresh)
            },
          },
        ]}
        header={bookingHeader}
      />
      {itemIdForUpdate !== '' ? (
        <TrainerAvailabilityEditModal
          show={itemIdForUpdate !== ''}
          itemIdForUpdate={itemIdForUpdate}
          onClose={() => {
            setItemIdForUpdate('')
          }}
          refresh={(itemId: any) => {
            if (itemId !== undefined) {
              setItemIdForUpdate(itemId)
            }
            refreshFunction()
          }}
        />
      ) : (
        <></>
      )}
    </>
  )
}

export default TrainerAvailablityCalendarList

import React, { useState, useEffect } from 'react'
import { Modal, Button, Form } from 'react-bootstrap'
import axios from 'axios'
import { useAuth } from '../../auth/core/AuthProvider'
import {
  fetchAreasForTrainers,
  fetchAreasList,
  fetchTrainers,
  fetchUsersAutocomplete,
} from '../../../utils/SelectListMethods'
import AutocompleteUsersWidget from './AutocompleteUsersWidget'
import Swal from 'sweetalert2'
import { hoursOptions } from '../../../utils/Constants'
import '../add_booking_modal.scss'

interface AddBookingModalProps {
  trainer: string
  area: string
  start_time: string
  end_time: string
  date: string
  onHide: () => void
  show: boolean
  bookingId?: string
  username?: string
}

interface Booking {
  trainer?: any
  user?: any
  area?: any
  startTime?: string
  endTime?: string
  date?: string
  status?: string
}

const AddBookingModal: React.FC<AddBookingModalProps> = ({
  trainer,
  area,
  start_time,
  end_time,
  date,
  onHide,
  show,
  bookingId,
  username,
}) => {
  const { token, hasValidRole } = useAuth()
  const [trainerOptions, setTrainersOptions] = useState<any[]>([])
  const [userOptions, setUserOptions] = useState<any[]>([])
  const [areaOptions, setAreaOptions] = useState<any[]>([])
  let role = 'admin'
  if (hasValidRole == 2) {
    role = 'trainer'
  }

  const formatDate = (dateString: string) => {
    const date = new Date(dateString)
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const day = String(date.getDate()).padStart(2, '0')
    return `${year}-${month}-${day}`
  }

  const fetchSingleData = async () => {
    if (bookingId) {
      axios
        .get(`${role}/bookings/${bookingId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setFormData({
            trainer: response.data.trainer ? response.data.trainer['@id'] : null,
            user: response.data.user ? response.data.user['@id'] : null,
            area: response.data.area ? response.data.area['@id'] : null,
            startTime: response.data.startTime,
            endTime: response.data.endTime,
            date: response.data.date ? formatDate(response.data.date) : '',
            status: response.data.status,
          })
        })
    }
  }

  const getAreaOptions = async () => {
    try {
      const response =
        role === 'admin'
          ? await fetchAreasList(token ?? '')
          : await fetchAreasForTrainers(token ?? '')
      setAreaOptions(response)
    } catch (error) {
      console.error('Error fetching area list:', error)
    }
  }

  const getTrainersOptions = async () => {
    fetchTrainers(token ?? '')
      .then((response) => {
        setTrainersOptions(response)
      })
      .catch((error) => {
        console.error('Error fetching branch list:', error)
      })
  }

  const getUsersOptionsForTrainers = async (char: string): Promise<any[]> => {
    try {
      const response = await fetchUsersAutocomplete(char, token ?? '', role)
      setUserOptions(response)
      return response
    } catch (error) {
      console.error('Error fetching branch list:', error)
      return []
    }
  }

  useEffect(() => {
    getAreaOptions()
    getTrainersOptions()
    if (bookingId) {
      fetchSingleData()
    }
  }, [])

  const isoStartTime = start_time ? new Date(`${date}T${start_time}`).toISOString() : ''
  const isoEndTime = end_time ? new Date(`${date}T${end_time}`).toISOString() : ''
  const [formData, setFormData] = useState<Booking>({
    trainer: trainerOptions.find((option) => option.value.split('/').pop() === trainer)?.value,
    user: username ?? null,
    area: areaOptions.find((option) => option.value.split('/').pop() === area)?.value,
    startTime: isoStartTime,
    endTime: isoEndTime,
    date: date,
    status: '',
  })
  useEffect(() => {
    setFormData((prevState) => ({
      ...prevState,
      trainer:
        trainerOptions.find((option) => option.value.split('/').pop() === trainer)?.value || null,
      area: areaOptions.find((option) => option.value.split('/').pop() === area)?.value || null,
      startTime: isoStartTime || '',
      endTime: isoEndTime || '',
      date: date || '',
      user: username ?? null,
      status: 'Pending',
    }))
  }, [trainer, area, start_time, end_time, date])

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target
    if (name == 'trainer' && value == '') {
      setFormData((prevData) => ({
        ...prevData,
        [name]: null,
      }))
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }))
    }
  }
  const handleChangeHour = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target

    setFormData((prevData) => ({
      ...prevData,
      [name]: `${date}T${value}`,
    }))
  }

  const handleChangeDate = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target

    setFormData((prevData) => ({
      ...prevData,
      date: new Date(value).toISOString(),
    }))
  }
  const handleSubmit = () => {
    if (bookingId) {
      axios
        .patch(`${role}/bookings/${bookingId}`, formData, {
          headers: {
            Accept: 'application/ld+json',
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/merge-patch+json',
          },
        })
        .then((response) => {
          if (response && response?.status === 200) {
            Swal.fire({
              title: 'Success',
              text: 'Booking updated successfully',
              icon: 'success',
              confirmButtonText: 'OK',
              confirmButtonColor: '#004F44',
            })
            onHide()
          } else {
            Swal.fire({
              title: 'Error',
              text: response?.data?.message || 'Error updating booking',
              icon: 'error',
              confirmButtonText: 'OK',
            })
          }
        })
        .catch((error) => {
          Swal.fire({
            title: 'Error',
            text: error || '',
            icon: 'error',
            confirmButtonText: 'OK',
          })
        })
    } else {
      if (role === 'trainer') {
        delete formData.trainer
      }

      axios
        .post(`${role}/bookings`, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/ld+json',
          },
        })
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            Swal.fire({
              title: 'Success',
              text: 'Booking created successfully',
              icon: 'success',
              timer: 700,
            })
            onHide()
          } else {
            Swal.fire({
              title: 'Error',
              text: response.data || '',
              icon: 'error',
            })
          }
        })
        .catch((error) => {
          Swal.fire({
            title: 'Error',
            text:
              error?.response?.data?.message ||
              'There was an issue creating the booking. Please try again.',
            icon: 'error',
            confirmButtonText: 'OK',
          })
        })
    }
  }

  const handleAutocompleteChange = (value: any) => {
    setFormData((prevData) => ({
      ...prevData,
      user: value,
    }))
  }
  useEffect(() => {
    if (areaOptions.length > 0 && area) {
      setFormData((prevState) => ({
        ...prevState,
        area: areaOptions.find((option) => option.value.split('/').pop() === area)?.value || null,
      }))
    }
  }, [areaOptions, area])

  useEffect(() => {
    if (trainerOptions.length > 0 && trainer) {
      setFormData((prevState) => ({
        ...prevState,
        trainer:
          trainerOptions.find((option) => option.value.split('/').pop() === trainer)?.value || null,
      }))
    }
  }, [trainerOptions, trainer])

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{bookingId ? 'Update Booking' : 'Add Booking'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          {hasValidRole === 1 && (
            <Form.Group controlId='formTrainer'>
              <Form.Label>Trainer</Form.Label>
              <Form.Control
                as='select'
                name='trainer'
                value={formData.trainer || null}
                onChange={handleChange}
              >
                <option value=''>Select Trainer</option>
                {trainerOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          )}
          <Form.Group controlId='formUserAutocomplete'>
            <AutocompleteUsersWidget
              defaultValue={username}
              handleChange={handleAutocompleteChange}
              getUsersOptionsForTrainers={getUsersOptionsForTrainers}
              required
            />
          </Form.Group>

          <Form.Group controlId='formArea'>
            <Form.Label>Area</Form.Label>
            <Form.Control
              as='select'
              name='area'
              onChange={handleChange}
              value={formData.area || null}
            >
              <option value=''>Select Area</option>
              {areaOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          <Form.Group controlId='formDate'>
            <Form.Label>Date</Form.Label>
            <Form.Control
              type='date'
              name='date'
              value={formData.date?.substring(0, 10)}
              onChange={handleChangeDate}
            />
          </Form.Group>
          <Form.Group controlId='formStartTime'>
            <Form.Label>Start Time</Form.Label>
            <Form.Control
              as='select'
              name='startTime'
              value={formData.startTime?.substring(11, 16)}
              onChange={handleChangeHour}
            >
              {hoursOptions
                .filter((option) => option.value >= '06:00' && option.value <= '22:00')
                .map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
            </Form.Control>
          </Form.Group>
          <Form.Group controlId='formEndTime'>
            <Form.Label>End Time</Form.Label>
            <Form.Control
              as='select'
              name='endTime'
              value={formData.endTime?.substring(11, 16)}
              onChange={handleChangeHour}
            >
              {hoursOptions
                .filter((option) => option.value >= '06:00' && option.value <= '22:00')
                .map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
            </Form.Control>
          </Form.Group>
          <Form.Group controlId='formStatus'>
            <Form.Label>Status</Form.Label>
            <Form.Control as='select' name='status' value={formData.status} onChange={handleChange}>
              <option value='Pending'>Pending</option>
              <option value='Approved'>Approved</option>
              <option value='Rejected'>Rejected</option>
            </Form.Control>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={onHide}>
          Close
        </Button>
        <Button variant='primary' onClick={handleSubmit}>
          {bookingId ? 'Update Booking' : 'Create Booking'}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default AddBookingModal

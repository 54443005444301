import { useIntl } from 'react-intl'
import { MenuItem } from './MenuItem'
import { useEffect, useState } from 'react'
import { meApi } from '../../../../../app/modules/auth/core/_requests'
import { useAuth } from '../../../../../app/modules/auth/core/AuthProvider'
import { MenuInnerWithSub } from './MenuInnerWithSub'
export function MenuInner() {
  const intl = useIntl()
  const { token, hasValidRole, loggedIn } = useAuth()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const checkAuth = async () => {
      if (!token) {
        setLoading(false)
        return
      }
      try {
        await meApi()
        setLoading(false)
      } catch (error) {
        setLoading(false)
      }
    }
    checkAuth()
  }, [token])

  if (loggedIn && hasValidRole === 2) {
    return (
      <>
        <MenuItem title={intl.formatMessage({ id: 'MENU.DASHBOARD' })} to='/dashboard' />
        <MenuItem to='bookings/requests' title='Bookings' />
        <MenuItem to='bookings/trainer-availability-calendars' title='Availability List View' />
        <MenuItem to='bookings/personal-available-calendar' title='Availability Calendar' />
      </>
    )
  } else if (loggedIn && hasValidRole === 1) {
    return (
      <>
        <MenuItem title={intl.formatMessage({ id: 'MENU.DASHBOARD' })} to='/dashboard' />
        <MenuItem to='users/list' title='Users' />
        <MenuItem to='branches/list' title='Branches' />
        <MenuItem to='areas/list' title='Areas' />
        <MenuInnerWithSub
          title='Booking'
          menuPlacement='bottom-start'
          menuTrigger={`{default:'click', lg: 'hover'}`}
          to='/scheduler'
        >
          <MenuItem to='/bookings/requests' title='Bookings' />
          <MenuItem to='/bookings/trainer-availability-calendars' title='Availability List View' />
          <MenuItem to='/bookings/personal-available-calendar' title='Availability Calendar' />
        </MenuInnerWithSub>
        <MenuItem to='payments/list' title='Payments' />
        <MenuInnerWithSub
          title='Settings'
          menuPlacement='bottom-start'
          menuTrigger={`{default:'click', lg: 'hover'}`}
          to={'/settings'}
        >
          <MenuItem to='/settings/area_types' title='Area Types' />
          <MenuItem to='/settings/membership_packages' title='Membership Packages' />
        </MenuInnerWithSub>
      </>
    )
  } else {
    return (
      <>
        <MenuItem title={intl.formatMessage({ id: 'MENU.DASHBOARD' })} to='/dashboard' />
      </>
    )
  }
}

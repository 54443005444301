import React, { useState } from 'react'
import { resetPasswordPostAPI } from '../core/_requests'

interface ResetPasswordModalProps {
  reset_token: string
  handleClose: () => void
}

const ResetPasswordModal: React.FC<ResetPasswordModalProps> = ({ reset_token, handleClose }) => {
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [error, setError] = useState<string | null>(null)
  const [success, setSuccess] = useState(false)

  const handleSubmit = async () => {
    if (newPassword !== confirmPassword) {
      setError('Passwords do not match!')
      return
    }

    try {
      const response = await resetPasswordPostAPI({ reset_token, password: newPassword })
      if (response && response.status === 'success') {
        setSuccess(true)
        setError(null)
        setTimeout(() => {
          handleClose()
        }, 2000)
      } else {
        setError(response.message || 'An error occurred')
      }
    } catch (err) {
      setError('An error occurred')
    }
  }

  return (
    <div className='modal' style={{ display: 'block' }}>
      <div className='modal-dialog'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h5 className='modal-title'>Reset Password</h5>
            <button
              type='button'
              className='btn-close'
              onClick={handleClose}
              aria-label='Close'
            ></button>
          </div>
          <div className='modal-body'>
            {success ? (
              <div className='alert alert-success'>Password reset successfully!</div>
            ) : (
              <>
                <div className='form-group'>
                  <label>New Password</label>
                  <input
                    type='password'
                    className='form-control'
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                  />
                </div>
                <div className='form-group mt-3'>
                  <label>Confirm Password</label>
                  <input
                    type='password'
                    className='form-control'
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </div>
                {error && <div className='alert alert-danger mt-5'>{error}</div>}
              </>
            )}
          </div>
          {!success && (
            <div className='modal-footer'>
              <button type='button' className='btn btn-primary' onClick={handleSubmit}>
                Reset Password
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default ResetPasswordModal

import { Route, Routes, Outlet, Navigate } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import DocumentTitle from 'react-document-title'
import PaymentsList from './PaymentsList'

const paymentsBreadcrumb: Array<PageLink> = [
  {
    title: 'Payments',
    path: '/payments',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const PaymentsPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='list/*'
          element={
            <>
              <DocumentTitle
                title={process.env.REACT_APP_NAME + ' | Payment History'}
              ></DocumentTitle>
              {/* <PageTitle breadcrumbs={paymentsBreadcrumb}>Payment History</PageTitle> */}
              <PaymentsList />
            </>
          }
        />
        <Route index element={<Navigate to='/payments/list' />} />
      </Route>
    </Routes>
  )
}

export default PaymentsPage
